@import 'styles/typography.scss';

* {
  box-sizing: border-box;
}

html {
  background-color: var(--color-dark-1);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// SAFARI FIX
* {
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

$color-grey-1: #15141b;
$color-malfunction: #ffb84e;
$color-critical: #ff1850;

:root {
  --color-dark-1: #000000;
  --color-dark-2: #15141a;
  --color-dark-3: #2f3239;
  --color-grey-1: #{$color-grey-1};
  --color-grey-2: #1e1f24;
  --color-grey-3: #30323a;
  --color-grey-4: #464954;
  --color-grey-5: #8c8eae;
  --color-white-1: #ffffff;
  --color-white-2: #fcfcfc;
  --color-white-3: #c6c1c1;
  --color-primary: #80ab53;
  --color-darker-primary: #174421;
  --color-warning: #d49840;
  --color-error: #ff1750;
  --color-ok: #53f477;
  --color-malfunction: #{$color-malfunction};
  --color-critical: #{$color-critical};
  --color-greenpowerup: #9bd065;
  --color-greenpowerup-gradient: linear-gradient(233deg, #9bd065 95%, #47c298 10%);
  --color-purple-1: #9f0fef;
  --color-purple-2: #91a1fd;
  --color-purple-3: #5d75fd;
  --color-purple-4: #1c297a;
  --color-bluesky: #06d7f6;
  --color-pink: #e45b94;
  --color-blue: #332ee3;

  --font-title-regular: #{$montserrat-regular};
  --font-title-bold: #{$montserrat-bold};
  --font-title-semibold: #{$montserrat-semibold};
  --font-title-extrabold: #{$montserrat-extrabold};
  --font-label-regular: #{$montserrat-regular};
  --font-label-bold: #{$montserrat-bold};
  --font-label-semibold: #{$montserrat-semibold};
  --font-label-extrabold: #{$montserrat-extrabold};
  --font-body-regular: #{$opensans-regular};
  --font-body-medium: #{$opensans-medium};
  --font-body-semibold: #{$opensans-semibold};
  --font-body-bold: #{$opensans-bold};

  --tree-view-width: 265px;
  --width-sidebar: calc(100% - var(--tree-view-width));
  --height-alarmlog-bar: 50px;
}
@keyframes loading-skeleton {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: -100% 50%;
  }
}
.react-loading-skeleton {
  width: 100%;
  border-radius: 0.25rem;
  display: inline-flex;
  line-height: 1;

  position: relative;
  overflow: hidden;
  z-index: 1; /* Necessary for overflow: hidden to work correctly in Safari */

  opacity: 0.7;
  animation: 4s linear 0s infinite normal none running loading-skeleton;
  background: rgba(0, 0, 0, 0) linear-gradient(90deg, rgb(36, 36, 36) 0%, rgb(36, 36, 36) 20%, rgb(41, 41, 41) 50%, rgb(36, 36, 36) 80%, rgb(36, 36, 36) 100%) repeat scroll 0% 0% / 200% 200%;
}

.availableSoon {
  position: relative;
  pointer-events: none;
  user-select: none;
  
  &:before {
    content: "";
    background: url('assets/stripes.png') repeat top left;
    opacity: .6;

    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  
  &:after {
    content: "AVAILABLE SOON";
    color: #fff;
    font-weight: bold;
    text-align: center;
    font-size: 1.2em;
    text-shadow: 0 0 30px #fff;
    
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  > * {
    filter: blur(2px);
  }

}

.lockedFeature {
  position: relative;
  user-select: none;
  flex: 1;

  &:after {
    content: '';
    mask: url('assets/lock.svg') no-repeat 50% 50%;
    background-color: #ccc;
    
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 15%;
    height: 15%;
    min-width: 20px;
    min-height: 20px;
  }
  
  > * {
    filter: blur(2px);
    pointer-events: none;
  }

}

// .flex {
//   flex: 1;
// }