@font-face {
  font-family: 'Montserrat-Regular';
  src: url('./fonts/Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('./fonts/Montserrat-Bold.ttf');
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('./fonts/Montserrat-SemiBold.ttf');
}

@font-face {
  font-family: 'Montserrat-ExtraBold';
  src: url('./fonts/Montserrat-ExtraBold.ttf');
}

@font-face {
  font-family: 'OpenSans-Regular';
  src: url('./fonts/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'OpenSans-Bold';
  src: url('./fonts/OpenSans-Bold.ttf');
}

@font-face {
  font-family: 'OpenSans-Medium';
  src: url('./fonts/OpenSans-Medium.ttf');
}

@font-face {
  font-family: 'OpenSans-SemiBold';
  src: url('./fonts/OpenSans-SemiBold.ttf');
}

$montserrat-regular: 'Montserrat-Regular', sans-serif;
$montserrat-bold: 'Montserrat-Bold', sans-serif;
$montserrat-semibold: 'Montserrat-SemiBold', sans-serif;
$montserrat-extrabold: 'Montserrat-ExtraBold', sans-serif;

$opensans-regular: 'OpenSans-Regular', sans-serif;
$opensans-bold: 'OpenSans-Bold', sans-serif;
$opensans-medium: 'OpenSans-Medium', sans-serif;
$opensans-semibold: 'OpenSans-SemiBold', sans-serif;
